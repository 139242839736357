<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
       <v-flex xs3 text-left>
              <span class="mainHeader">Add Jewellery Admin</span>
            </v-flex>
      <v-flex xs12 py-6 text-left>
        <v-card
          tile
        
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout wrap pa-4>
          <v-flex pa-4 xs6>
            <v-layout wrap>
              <v-flex xs12 pb-1><span class="subhed">Email Address</span></v-flex>
               <v-flex xs12 pb-3>
             <v-text-field
            label="Email"
            outlined
            v-model="email"
            type="email"
            hide-details
            dense
          ></v-text-field>
           </v-flex>
            <v-flex xs12 pb-2><span class="subhed">Jewellery Name</span></v-flex>
               <v-flex xs12 pb-3>
             <v-text-field
            label="Jewellery Name"
            outlined
            v-model="name"
            type="text"
            hide-details
            dense
          ></v-text-field>
           </v-flex>
           <v-flex xs12 pb-2><span class="subhed">Phone Number</span></v-flex>
               <v-flex xs12 align-self-end>
             <v-text-field
            label="Phone"
            outlined
            v-model="phone"
            type="number"
            hide-details
            dense
          ></v-text-field>
           </v-flex>
              
            </v-layout>
          </v-flex>
           <v-flex pa-4 xs6>
            <v-layout wrap justify-end>
                <v-flex xs12 pb-1><span class="subhed">Username</span></v-flex>
               <v-flex xs12>
             <v-text-field
            label="username"
            outlined
            v-model="username"
            type="text"
            hide-details
            dense
          ></v-text-field>
           </v-flex>
             <v-flex xs12><span class="subhed">Address</span></v-flex>
               <v-flex xs12>
             <v-textarea
            label="Address"
            outlined
            v-model="address"
            type="Address"
            hide-details
            dense
          ></v-textarea>
           </v-flex>
           <!-- <v-flex xs2 pt-2>
            <v-btn dark color="#3F053C" @click="validateInput()">
              Save
            </v-btn>
           </v-flex> -->
            </v-layout>
           
          </v-flex>
          </v-layout>
          <v-layout wrap py-4 px-8>
              <v-flex xs12>
                <v-divider style="border-color:black; !important"></v-divider>
              </v-flex>
            </v-layout>
            <v-layout wrap py-2 px-8>
              <v-flex class="subhead3" text-left>Registration</v-flex>
            </v-layout>
            <v-layout wrap pb-4 px-8>
              <v-flex xs4>
                <v-layout wrap px-4>
                  <v-flex xs12 text-left>
                    <span class="subhead5">GSTIN</span>
                  </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        type="text"
                        placeholder="Enter GSTIN"
                        v-model="gstin"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4> 
                <v-layout wrap px-4>
                  <v-flex xs12 text-left>
                    <span class="subhead5">TAN </span>
                  </v-flex>
                    <v-flex xs12>
                      <v-text-field type="text"
                        placeholder="Enter TAN"
                        v-model="tan"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-layout wrap px-4>
                  <v-flex xs12 text-left>
                    <span class="subhead5"> BIS License </span>
                  </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        
                       
                        type="text"
                        placeholder="Enter BIS License"
                        v-model="bisliscence"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap pb-4 px-8>
              <v-flex xs4>
                <v-layout wrap px-4>
                  <v-flex xs12 text-left>
                    <span class="subhead5">Insurance Policy No </span>
                  </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        
                       
                        type="text"
                        placeholder="Enter Policy No "
                        v-model="policyno"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4> 
                <v-layout wrap px-4>
                  <v-flex xs12 text-left>
                    <span class="subhead5"> Company ID </span>
                  </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        
                       
                        type="text"
                        placeholder="Enter Company ID"
                        v-model="companyid"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4> 
                <v-layout wrap px-4>
                  <v-flex xs12 text-left>
                    <span class="subhead5">Place of supply</span>
                  </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        
                       
                        type="text"
                        placeholder="Enter Place Of Supply"
                        v-model="placeofSupply"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap py-4 px-8>
              <v-flex xs12>
                <v-divider style="border-color:black; !important"></v-divider>
              </v-flex>
            </v-layout>
            <v-layout wrap py-2 px-8>
              <v-flex class="subhead3" text-left>Bank Details </v-flex>
            </v-layout>
            <v-layout wrap pb-4 px-8>
              <v-flex xs4>
                <v-layout wrap px-4>
                  <v-flex xs12 text-left>
                    <span class="subhead5">Bank Name</span>
                  </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        
                       
                        type="text"
                        placeholder="Enter Bank Name"
                        v-model="bankname"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4> 
                <v-layout wrap px-4>
                  <v-flex xs12 text-left>
                    <span class="subhead5">Account No</span>
                  </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        
                       
                        type="number"
                        placeholder="Enter Account No"
                        v-model="accno"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-layout wrap px-4>
                  <v-flex xs12 text-left>
                    <span class="subhead5">IFSC Code</span>
                  </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        
                       
                        type="text"
                        placeholder="Enter IFSC Code"
                        v-model="ifsc"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap pb-4 px-8>
              <v-flex xs4>
                <v-layout wrap px-4>
                  <v-flex xs12 text-left>
                    <span class="subhead5">Branch</span>
                  </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        
                       
                        type="text"
                        placeholder="Enter Branch"
                        v-model="branch"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4> 
                <v-layout wrap px-4>
                  <v-flex xs12 text-left>
                    <span class="subhead5"> Pan Card No </span>
                  </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        placeholder="Enter Pan Card No"
                        v-model="pan"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                </v-layout>
              </v-flex>
              <!-- <v-flex xs4>
                <v-card outlined>
                  <v-img
                      v-if="userData.image && !imagePreview"
                      :src="baseURL + userData.image"
                      width="150px"
                      height="150px"
                      style="align-center"
                      contain
                    />
                    <img
                      v-if="imagePreview"
                      :src="imagePreview"
                      v-show="showPreview"
                      width="150px"
                      height="150px"
                      class="align-center"
                      contain
                    />

                    </v-card>
              </v-flex> -->
              <v-flex xs4 px-4 align-self-end >
                <v-btn outlined block color="grey"  @click="$refs.showImage.click()"
                      >Upload Profile Picture
                      <v-item-group>
                        <input
                          v-show="false"
                          label="Update Profile Picture"
                          ref="showImage"
                          id="file"
                          type="file"
                          style="display: none"
                          @change="showImage"
                        />
                      </v-item-group>
                    </v-btn>
              </v-flex>
              <!-- <v-flex xs4 pt-4>
                <v-layout wrap px-4 justify-end fill-height>
                  <v-flex xs12 sm7 text-right align-self-end>
                <v-btn type="submit"
                color="#3F053C"
                dark block 
                @click="validateInput()"
                ><v-icon>mdi-plus</v-icon>Add</v-btn
              >
            </v-flex>
                </v-layout>
              </v-flex> -->
            </v-layout>
            <v-layout wrap pb-4 px-8>
                  <v-spacer></v-spacer>
              <v-flex xs4>
                <v-layout wrap px-4 justify-end fill-height>
                  <v-flex xs12 sm7 text-center align-self-end>
                    <v-btn type="submit"
                color="#3F053C"
                dark block 
                @click="validateInput()"
                ><v-icon>mdi-plus</v-icon>Add</v-btn
              >
            </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      username:null,
      name:null,
      address:null,
      phone:null,
      email:null,
      gstin:null,
      tan:null,
      bisliscence: null,
      policyno: null,
      companyid: null,
      bankname:null,
      accno:null,
      ifsc:null,
      branch:null,
      pan:null,
      placeofSupply:null,
       //upload image
       files: null,
      imagePreview: "",
    };
  },
  methods: {
     validateInput() {
      if (!this.email) {
        this.msg = "Please provide email";
        this.showsnackbar = true;
        return;
      } else if (!this.name) {
        this.msg = "Please provide name";
        this.showsnackbar = true;
        return;
      } else if (!this.phone) {
        this.msg = "Please provide phone";
        this.showsnackbar = true;
        return;
      } else if (!this.username) {
        this.msg = "Please provide username";
        this.showsnackbar = true;
        return;
      }  else if (!this.address) {
        this.msg = "Please provide address";
        this.showsnackbar = true;
        return;
      }  else {
        this.addAdmin();
      }
    },
    // profile image upload
    showImage(e) {
      this.file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
      };
      this.showPreview = true;
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
      console.log("file====",this.file)
    },
    uploadImage() {
      let formData = new FormData();
      formData.append("photos", this.file);

      axios({
        method: "POST",
        url: "/user/uploadimagesingle",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.$router.push("/settingsAdmin/profile");
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //
      addAdmin() {
      
      axios({
        url: "/user/jewelleryregister",
        method: "POST",
        data: {
          username:this.username,
          email:this.email,
          jewelleryName: this.name,
          phone: this.phone,
          location: this.address,
          GSTIN: this.gstin,
          TAN: this.tan,
          BisLicense: this.bisliscence,
          policyNo: this.policyno,
          companyId: this.companyid,
          bankName: this.bankname,
          accountNo: this.accno,
          IFSCCode: this.ifsc,
          branch: this.branch,
          pancardNo: this.pan,
          stateandCode: this.placeofSupply,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status==true) {
            if (response.data.id) {
              console.log("this.imageArray=",this.imageArray);
              if (this.file) {
                console.log("file=", this.file);
                this.uploadImage();
              }
            }
            this.msg =response.data.msg;
            this.showsnackbar = true;
          this.email=null;
          this.phone=null;
          this.name=null;
          this.address=null;
          this.username=null;
          location.reload();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
      
     
    },
  },
};
</script>
